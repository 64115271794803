/*==========  Non-Mobile First Method  ==========*/
/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  body #home-grey-boxes div {
    width: 45%;
    min-height: 40rem;
  }
  body .navbar {
    min-height: 8rem !important;
    background: rgba(0, 0, 0, 0.7);
  }
  body .navbar .navbar-brand img {
    width: 14rem !important;
    position: absolute !important;
    top: 0.8rem !important;
    left: 50% !important;
    margin-left: -8rem !important;
  }
  body .navbar .navbar-nav {
    margin: 0 auto !important;
    padding-top: 1rem;
    width: 90%;
  }
  body .navbar .navbar-nav li:nth-child(3) {
    margin-right: 8rem;
  }
  body .navbar .navbar-nav li:nth-child(4) {
    margin-left: 8rem;
  }
  body .navbar .navbar-nav li:nth-child(1) {
    margin-left: -4.6rem;
  }
  body .navbar .navbar-nav li a {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  body .navbar #navbar {
    margin: 0 !important;
  }
  body #home-review {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  body #home-review #review-wrapper {
    width: 90%;
  }
  body #bespoke-grey-boxes .grey-box {
    min-height: 42rem;
  }
  body #about-grey-boxes .grey-box {
    min-height: 28rem;
  }
  body #product-item #product-left {
    margin-bottom: 2rem;
    padding: 1rem 0rem;
  }
  body #product-item #product-right {
    padding: 1rem 1rem 1rem 2.6rem;
  }
  body #product-item #product-right #product-enquiry-breakdown {
    margin-top: 2rem;
    float: left;
  }
  body #product-item #product-right #price-calculator small {
    display: block;
  }
  body #contact-basic #extra-info {
    text-align: left;
  }
  body footer {
    padding: 6rem 1.5rem;
  }
}

/* Medium Devices, Desktops */
@media only screen and (width: 768px) {
  body .navbar {
    min-height: 8rem !important;
    background: rgba(0, 0, 0, 0.7);
  }
  body .navbar .navbar-brand img {
    width: 10rem !important;
    position: absolute !important;
    top: 2rem !important;
    left: 50% !important;
    margin-left: -5rem !important;
  }
  body .navbar .navbar-nav {
    margin: 0 !important;
    padding-top: 1rem !important;
    width: 100% !important;
  }
  body .navbar .navbar-nav li:nth-child(1) {
    margin-left: 0 !important;
  }
  body .navbar .navbar-nav li:nth-child(3) {
    margin-right: 6rem !important;
  }
  body .navbar .navbar-nav li:nth-child(4) {
    margin-left: 6rem !important;
  }
  body .navbar .navbar-nav li a {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    font-size: 1.1rem !important;
  }
  body .navbar #navbar {
    margin: 0 !important;
  }
  #bespoke-grey-boxes .grey-box {
    min-height: 38rem;
  }
  #about-grey-boxes .grey-box {
    min-height: 23rem;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 767px) {
  body .video iframe {
    width: 96% !important;
  }
  body .featured {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  body #home-intro {
    height: 75vh;
  }
  body #home-intro .slider {
    height: 75vh;
  }
  body #home-intro .slider h1 {
    width: 98%;
    font-size: 3.6rem;
    margin-top: 25vh;
  }
  body #home-intro .slider span {
    width: 98%;
    font-size: 3rem;
  }
  body #home-intro .slider .smaller-mobile {
    margin: 1rem 0 0;
  }
  body #home-events {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  body #home-events #events-wrapper {
    width: 100%;
  }
  body #home-events #events-wrapper h3 {
    font-size: 2rem;
    width: 80%;
  }
  body #home-events #events-wrapper #event-image {
    margin-bottom: 2rem;
  }
  body #home-events #events-wrapper .event-stars {
    margin-bottom: 4rem;
    width: 20rem;
  }
  body #home-events #events-wrapper a {
    bottom: 0;
  }
  body #home-events p {
    margin-bottom: 1rem;
    font-size: 2rem;
  }
  body #home-grey-boxes div {
    width: 45%;
    min-height: 40rem;
  }
  body #home-wow {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  body #home-wow #wow-box {
    padding: 1.8rem;
  }
  body #products-intro h1 {
    font-size: 2.6rem;
  }
  body #products-intro h1 span {
    display: block;
  }
  body #product-item #product-left {
    margin-bottom: 2rem;
    padding: 1rem 0rem;
  }
  body #product-item #product-right {
    padding: 1rem 1rem 1rem 2.6rem;
  }
  body #product-item #product-right #product-enquiry-breakdown {
    margin-top: 2rem;
    float: left;
  }
  body #product-item #product-right #price-calculator small {
    display: block;
  }
  body #about-head-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  body #about-intro h1, body #bespoke-intro h1, body #contact-page h1, body #review-intro h1 {
    font-size: 2.6rem;
  }
  body .navbar {
    min-height: 8rem !important;
    background: rgba(0, 0, 0, 0.8);
  }
  body .navbar .navbar-brand img {
    width: 16rem !important;
    position: absolute !important;
    top: 0.8rem !important;
    left: 50% !important;
    margin-left: -8rem !important;
  }
  body .navbar .navbar-nav {
    margin: 0 !important;
    padding-top: 1rem;
  }
  body .navbar .navbar-nav li:nth-child(1), body .navbar .navbar-nav li:nth-child(2), body .navbar .navbar-nav li:nth-child(3) {
    margin: 0 !important;
  }
  body .navbar .navbar-nav li.dropdown .dropdown-menu {
    position: relative;
    text-align: center;
    background: transparent;
    padding: 0;
    margin: 0;
  }
  body .navbar .navbar-nav li.dropdown .dropdown-menu li {
    margin: 0 !important;
  }
  body .navbar .navbar-nav li.dropdown .dropdown-menu li a {
    padding: 1rem 0;
  }
  body .navbar .navbar-nav li.dropdown .dropdown-menu:after {
    content: none;
  }
  body .navbar #navbar {
    margin: 0 !important;
    height: 100%;
    overflow: hidden;
  }
  body footer {
    padding: 6rem 1.5rem;
  }
  body footer #horse {
    display: none;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  body .featured {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  body #home-intro h1 {
    width: 98%;
    font-size: 3.6rem;
  }
  body #home-intro span {
    width: 98%;
    font-size: 3rem;
  }
  body #home-review {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  body #home-review #review-wrapper {
    width: 100%;
  }
  body #home-review #review-wrapper h3 {
    font-size: 2rem;
    width: 80%;
  }
  body #home-review #review-wrapper #review-image {
    margin-bottom: 2rem;
  }
  body #home-review #review-wrapper .review-stars {
    padding-bottom: 2rem;
  }
  body #home-review #review-wrapper .review-stars img {
    width: 20rem;
  }
  body #home-review p {
    margin-bottom: 1rem;
    font-size: 2rem;
  }
  body #home-grey-boxes div {
    width: 90%;
    min-height: 32rem;
  }
  body #home-wow {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  body #home-wow #wow-box {
    padding: 1.8rem;
    font-size: 2.6rem;
    word-spacing: 1rem;
  }
  body #products-intro h1 {
    font-size: 2.6rem;
  }
  body #products-intro h1 span {
    display: block;
  }
  body #product-item {
    margin-top: 0rem;
  }
  body #product-item #product-left {
    margin-bottom: 0rem;
    padding: 1rem 0rem;
    overflow: auto;
  }
  body #product-item #product-right {
    padding: 1rem 0rem;
  }
  body #product-item #product-right #product-enquiry-breakdown {
    margin-top: 2rem;
    float: left;
  }
  body #product-item #product-right #price-calculator small {
    display: block;
  }
  body #about-head-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  body #about-intro h1, body #bespoke-intro h1, body #contact-page h1, body #review-intro h1 {
    font-size: 2.6rem;
  }
  body .navbar {
    min-height: 8rem !important;
    background: rgba(0, 0, 0, 0.8);
  }
  body .navbar .navbar-brand img {
    width: 16rem !important;
    position: absolute !important;
    top: 0.8rem !important;
    left: 50% !important;
    margin-left: -8rem !important;
  }
  body .navbar .navbar-nav {
    margin: 0 !important;
    padding-top: 1rem;
  }
  body .navbar .navbar-nav li:nth-child(1), body .navbar .navbar-nav li:nth-child(2), body .navbar .navbar-nav li:nth-child(3) {
    margin: 0 !important;
  }
  body .navbar .navbar-nav li.dropdown .dropdown-menu {
    position: relative;
    text-align: center;
    background: transparent;
    padding: 0;
    margin: 0;
  }
  body .navbar .navbar-nav li.dropdown .dropdown-menu li {
    margin: 0 !important;
  }
  body .navbar .navbar-nav li.dropdown .dropdown-menu li a {
    padding: 1rem 0;
  }
  body .navbar .navbar-nav li.dropdown .dropdown-menu:after {
    content: none;
  }
  body .navbar #navbar {
    margin: 0 !important;
    height: 100%;
    overflow: hidden;
  }
  body .navbar .navbar-toggle {
    margin-top: 12px;
  }
  body footer {
    padding: 6rem 1.5rem;
  }
}

/* Custom, iPhone Retina */
